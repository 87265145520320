import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as _ from 'underscore';
import { EntitlementsService } from './entitlements.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(private cookieService: CookieService) {
    this.authenticated = new BehaviorSubject(this.isAuthenticated());
  }

  authenticated: BehaviorSubject<any>;
  jwtHelper: JwtHelperService = new JwtHelperService();


  setToken(token: string): void {
    if (environment.production) {
      this.cookieService.delete('SESSIONID');
      this.cookieService.set('SESSIONID', token, 1, '/', '.sandbox.co.in', true, 'Strict');
    }
    else {
      this.cookieService.delete('SESSIONID');
      this.cookieService.set('SESSIONID', token, undefined, undefined, undefined, false, 'Strict');
    }
  }

  getToken(): string {
    return this.cookieService.get('SESSIONID');
  }

  deleteToken(): void {
    if (environment.production) {
      this.cookieService.delete('SESSIONID', '/', '.sandbox.co.in', true, 'Strict');
    }
    else {
      this.cookieService.delete('SESSIONID', '/', 'localhost', true, 'Strict');
    }
  }

  getUsername(): string | null {
    if (this.getToken() != null && this.getToken() != '') {
      let jwtToken = this.jwtHelper.decodeToken(this.getToken());
      return jwtToken['username'];
    }
    return null;
  }

  isAuthenticated(): boolean {
    try {
      if (this.getToken() != null) {

        let token = this.getToken().split('.');

        if (token && _.size(token) == 3 && this.jwtHelper.decodeToken(this.getToken()) && !this.jwtHelper.isTokenExpired(this.getToken())) {
          return true;
        }
      }
    } catch (error) {
      return false;
    }
    return false;
  }

}

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  authentication_host: string;
  constructor(public auth: AuthService) {
    this.authentication_host = environment.authentication_host
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.auth.isAuthenticated()) {
      request = request.clone({
        setHeaders:
        {
          'authorization': this.auth.getToken(),
        },
      });
    }
    else {
      window.location.href = this.authentication_host;
    }


    return next.handle(request);

  }
}

