import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { Subject, takeUntil } from 'rxjs';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { EntitlementsService } from '../../services/entitlements.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UserActions } from '../../store/user/user.actions';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css'],
  standalone: true,
  imports: [RiveModule, AngularMaterialModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule]
})
export class SetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  showRive: boolean = false;
  destroy$: Subject<void> = new Subject<void>();
  passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=\|"':;<,>.?\/\\])[A-Za-z\d!@#$%^&*()_\-+=\|"':;<,>.?\/\\]{8,}$/;

  isLengthValid: boolean = false;
  isLowercaseValid: boolean = false;
  isUppercaseValid: boolean = false;
  isNumberValid: boolean = false;
  isSpecialCharacterValid: boolean = false;

  constructor(private entitlementService: EntitlementsService,
    private router: Router,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private store: Store,
    private actions$: Actions) {

    this.passwordForm = new RxFormBuilder().group({
      check_password: ['', RxwebValidators.notEmpty({ message: "Password is required" })],
      new_password: ['', RxwebValidators.notEmpty({ message: "Password is required" })],
    });

    this.passwordForm.controls['check_password'].valueChanges
      .subscribe((newPassword: string) => {
        this.isLengthValid = newPassword.length >= 8;
        this.isLowercaseValid = /[a-z]/.test(newPassword);
        this.isUppercaseValid = /[A-Z]/.test(newPassword);
        this.isNumberValid = /\d/.test(newPassword);
        this.isSpecialCharacterValid = /[!@#$%^&*()_\-+=\|"':;<,>.?\/\\]/.test(newPassword);
      });
  }

  ngOnInit() {
  }

  checkFormValidation() {
    this.passwordForm.get('check_password')!.markAsTouched()
    this.passwordForm.get('new_password')!.markAsTouched()
    // this.passwordForm.get('check_password')!.markAsDirty()
    // this.passwordForm.get('new_password')!.markAsDirty()

    // validates password field
    if (!this.passwordForm.controls['check_password'].value) {
      this.passwordForm.controls['check_password'].setErrors({ required: true })
    }
    else if (this.passwordForm.controls['check_password'].value && !this.passwordRegex.test(this.passwordForm.controls['check_password'].value)) {
      this.passwordForm.controls['check_password'].setErrors({ invalidPassword: true })
    }

    // validates confirm password field
    if (!this.passwordForm.controls['check_password'].value && !this.passwordForm.controls['new_password'].value) {
      this.passwordForm.controls['new_password'].setErrors({ required: true })
    }
    else if (this.passwordForm.controls['check_password'].value && this.passwordForm.controls['check_password'].value != this.passwordForm.controls['new_password'].value) {
      this.passwordForm.controls['new_password'].setErrors({ match_error: true })
    }
  }

  onNewPasswordChange() {
    if (this.passwordForm.controls['check_password'].value == this.passwordForm.controls['new_password'].value) {
      this.passwordForm.controls['new_password'].setErrors({ match_error: false })
      this.passwordForm.controls['new_password'].errors
    }
  }

  setPassword() {
    this.checkFormValidation();

    if (this.passwordForm.valid) {
      this.showRive = true;
      const passwordsBody = {
        "new_password": this.passwordForm.controls['new_password'].value
      }
      this.store.dispatch(UserActions.SET_PASSWORD({ passwordBody: passwordsBody }));

      this.actions$.pipe(ofType(UserActions.SET_PASSWORD_SUCCESS), takeUntil(this.destroy$)).subscribe(() => {
        this.snackbarService.openSnackBar('Password Set successfully', undefined);
        this.showRive = false;
        this.router.navigate(['../'], {
          relativeTo: this.route,
        })
      })

      this.actions$.pipe(ofType(UserActions.SET_PASSWORD_FAILED), takeUntil(this.destroy$)).subscribe(() => {
        this.snackbarService.openSnackBar('Failed to Set Password', undefined);
        this.showRive = false;
      })
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions to prevent memory leaks
    this.destroy$.next();
    this.destroy$.complete();
  }

}
