import { Routes } from '@angular/router';
import { EditDetailsComponent } from './components/edit-details/edit-details.component';
import { EditEmailComponent } from './components/edit-email/edit-email.component';
import { EditPasswordComponent } from './components/edit-password/edit-password.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { isPasswordSetGuard } from './route-guards/is-password-set.guard';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: MyAccountComponent
    },
    {
        path: 'details',
        component: EditDetailsComponent
    },
    {
        path: 'password',
        component: EditPasswordComponent
    },
    {
        path: 'email',
        component: EditEmailComponent
    },
    {
        path: 'set-password',
        component: SetPasswordComponent, canActivate: [isPasswordSetGuard]
    },
    {
        path: 'logout',
        component: LogoutComponent
    }
];
