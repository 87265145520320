import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService) {
    if (typeof window !== "undefined") {
      this.authService.deleteToken();
      window.location.href = environment.authentication_host + '/login' + '?url=' + btoa(window.location.href);
    }

  }

  ngOnInit() {
  }

}
