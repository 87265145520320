<ng-container *ngIf="loading; else content">
  <app-my-account-skeleton-loading />
</ng-container>

<ng-template #content>
  <div class="flex flex-col mt-[32px] items-center">
    <div class="flex flex-col w-[900px] items-center">
      <div class="flex flex-col items-center">
        <ngx-avatars [round]="true" bgColor="#D3D3EE" fgColor="#393993" size="70" [textSizeRatio]="2"
          [name]="getInitials(user?.firstName, user?.lastName)">
        </ngx-avatars>
        <div class="flex flex-col mt-[16px] items-center">
          <span class="text-subheading-1-medium text-dark">Welcome, {{ this.user?.firstName }} {{ this.user?.lastName
            }}</span>
          <span class="mt-[4px] text-caption-medium text-medium">Joined on {{ this.user?.createdAt | dateFormat
            }}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col mt-[40px]">
        <span class="text-body-2-medium text-dark">Personal details</span>
        <span class="mt-[8px] text-caption-medium text-medium">Manage your personal identity across sandbox</span>
      </div>
      <mat-card appearance="outlined"
        class="flex flex-col border rounded-lg border-solid border-grey-bg-900 w-[900px] mt-[16px] mat-elevation-z0">
        <div class="flex flex-col w-[900px] py-[8px]">
          <mat-card-content class="flex flex-row px-[24px] py-[16px]">
            <span class="text-body-1-medium text-medium">Full name</span>
            <span class="text-body-1-medium text-navy-blue-800 ml-[195px]">{{ this.user?.firstName }} {{
              this.user?.lastName }}</span>
          </mat-card-content>
          <mat-card-content class="flex flex-row px-[24px] py-[16px]">
            <span class="text-body-1-medium text-medium">Phone number</span>
            <ng-container *ngIf="this.user?.phone; else notAvailable">
              <span class="text-body-1-medium text-navy-blue-800 ml-[156px]">
                {{this.user?.phone.slice(0,3)}} {{formatPhoneNumber(this.user?.phone)}}</span>
            </ng-container>
            <ng-template #notAvailable>
              <span class="text-body-1-medium text-medium ml-[156px]">
                Not available</span>
            </ng-template>
          </mat-card-content>
          <mat-card-content class="flex flex-row px-[24px] py-[16px]">
            <span class="text-body-1-medium text-medium">Business name</span>
            <span *ngIf="this.user?.business" class="text-body-1-medium text-navy-blue-800 ml-[152px]">{{
              this.user?.business
              }}</span>
          </mat-card-content>
        </div>
        <mat-divider class="border-grey-bg-900"></mat-divider>
        <mat-card-actions class="flex flex-row w-[900px] hover:bg-grey-bg-400" matRipple>
          <button routerLink="./details">
            <span class="text-navy-blue-600 text-body-1-medium pr-[745px] px-[16px] py-[16px]">Change Details</span>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-col mt-[40px]">
        <span class="text-body-2-medium text-dark">Login and security</span>
        <span class="mt-[8px] text-caption-medium text-medium">Manage your login credentials used to access
          Sandbox</span>
      </div>
      <div class="flex flex-row mt-[16px]">
        <mat-card appearance="outlined"
          class="flex flex-col border rounded-lg border-solid border-grey-bg-900 w-[442px]">
          <mat-card-content class="flex flex-col py-[16px] px-[24px]">
            <mat-icon class="material-symbols-rounded text-navy-blue-600 h-[32px] w-[32px] my-icon">email</mat-icon>
            <span class="flex flex-row text-body-1-medium text-medium mt-[16px]">Email Address</span>
            <span class="flex flex-row text-body-1-medium mt-[8px] text-dark">{{
              this.user?.email
              }}</span>
          </mat-card-content>

          <mat-divider class="bg-grey"></mat-divider>

          <mat-card-actions class="flex flex-row hover:bg-grey-bg-400" matRipple>
            <button routerLink="./email">
              <span class="text-navy-blue-600 text-body-1-medium py-[16px] pl-[16px] pr-[300px]">Change Email</span>
            </button>
          </mat-card-actions>
        </mat-card>
        <mat-card appearance="outlined"
          class="flex flex-col ml-[16px] border rounded-lg border-solid border-grey-bg-900 w-[442px] mat-elevation-z0">
          <mat-card-content class="flex flex-col py-[16px] px-[24px]">
            <mat-icon class="material-symbols-rounded text-navy-blue-600 h-[32px] w-[32px] my-icon">password</mat-icon>
            <span class="flex flex-row text-body-1-medium text-medium mt-[16px]">Password</span>
            <span class="flex flex-row text-body-1-medium mt-[8px] text-dark">{{ passwordMessage }}</span>
          </mat-card-content>

          <mat-divider class="border-grey-bg-900"></mat-divider>

          <mat-card-actions class="flex flex-row hover:bg-grey-bg-400" matRipple>
            <button *ngIf="this.user?.isPasswordSet" routerLink="./password">
              <span class="text-navy-blue-600 text-body-1-medium py-[16px] pl-[16px]">Change Password</span>
            </button>
            <button *ngIf="!this.user?.isPasswordSet" routerLink="./set-password">
              <span class="text-navy-blue-600 text-body-1-medium py-[16px] pl-[16px]">Set Password</span>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</ng-template>