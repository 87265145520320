<div class="flex flex-col mt-[32px] items-center mb-[134px]">
  <div class="flex flex-col w-[603px]">
    <div class="flex flex-row">
      <button routerLink="../" class="flex flex-row content-center">
        <mat-icon class="material-symbols-rounded text-navy-blue-600 size-5">arrow_back</mat-icon>
        <span class="text-navy-blue-600 text-caption-medium ml-[4px]">Back</span>
      </button>
    </div>
    <div class="flex flex-col mt-[8px]">
      <span class="text-body-2-medium text-dark">Personal Details</span>
      <span class="mt-[4px] text-body-1-regular text-medium">Changes to your personal details will be reflected across
        your Sandbox account</span>
    </div>
    <form [formGroup]="detailsForm">
      <div class="flex flex-col mt-[40px]">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="shadow-navy-blue-600 w-[600px] text-border-grey rounded-md">
            <mat-label>First Name</mat-label>
            <input required matInput formControlName="firstName" type="text" placeholder="Aarav"
              class="text-body-1-medium text-dark">
            <mat-error>
              <ng-container *ngIf="
                      detailsForm.controls['firstName'].errors &&
                      (detailsForm.controls['firstName'].dirty || detailsForm.controls['firstName'].touched)
                    ">
                <ng-container *ngFor="let error of detailsForm.controls['firstName'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="shadow-navy-blue-600 w-[600px] text-border-grey rounded-md">
            <mat-label>Last Name</mat-label>
            <input required matInput formControlName="lastName" type="text" placeholder="Sharma"
              class="text-body-1-medium text-dark">
            <mat-error>
              <ng-container *ngIf="
                        detailsForm.controls['lastName'].errors &&
                        (detailsForm.controls['lastName'].dirty || detailsForm.controls['lastName'].touched)
                      ">
                <ng-container *ngFor="let error of detailsForm.controls['lastName'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-row gap-[8px] w-[600px]">
        <mat-form-field appearance="outline" class="w-[94px]">
          <mat-select class="flex w-[94px]  overflow-hidden" [(value)]="selectedCountry"
            (selectionChange)="changePhoneNumber()" panelWidth="400px">
            <mat-select-trigger>
              <img *ngIf="selectedCountry" matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 mr-[8px]" />
            </mat-select-trigger>
            <mat-option *ngFor=" let c of countryList" [value]="c">
              <div class=" flex">
                <img src="{{c.flag}}" alt="flag" class="h-5 w-7 mr-3" />
                <span>{{c.name}}</span>
              </div>
            </mat-option>
          </mat-select>
          <mat-icon class="text-medium py-2 material-symbols-rounded" matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-[498px] bg-white" floatLabel="always">
          <mat-label>Phone (optional)</mat-label>
          <p matTextPrefix class="text-body-1-regular text-medium mr-[12px]" *ngIf="selectedCountry">
            +{{selectedCountry.numeric_code}}</p>
          <input matInput formControlName="phone" type="text" placeholder="9876543210"
            class="text-body-1-medium text-dark">
          <mat-error>
            <ng-container *ngIf="
                      detailsForm.controls['phone'].errors &&
                      (detailsForm.controls['phone'].dirty || detailsForm.controls['phone'].touched)
                    ">
              <ng-container *ngFor="let error of detailsForm.controls['phone'].errors | keyvalue">
                <ng-container *ngIf="error.key">
                  <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="w-[600px] shadow-navy-blue-600 text-border-grey rounded-md">
            <mat-label>Business name</mat-label>
            <input required matInput formControlName="business" type="text" placeholder="Acme Inc."
              class="text-body-1-medium text-dark ">
            <mat-error>
              <ng-container *ngIf="
                        detailsForm.controls['business'].errors &&
                        (detailsForm.controls['business'].dirty || detailsForm.controls['business'].touched)
                      ">
                <ng-container *ngFor="let error of detailsForm.controls['business'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-row mt-[16px]">
        <ng-container *ngIf="!showRive; else proceed">
          <button type="submit" (click)=" isValid()?saveDetails():showError()" mat-flat-button color="primary"
            class="w-[120px]  h-[48px]">
            <span class="text-white text-caption-medium">Save</span>
          </button>
        </ng-container>

        <ng-template #proceed>
          <button mat-flat-button color="primary" class="w-[120px]  h-[48px]">
            <canvas riv="console-cta-button-loading-animation" width="48" height="48">
              <riv-player name="loading-1" play></riv-player>
            </canvas>
          </button>
        </ng-template>

        <button type="submit" mat-stroked-button routerLink="../" class="ml-4 h-[48px] min-w-[120px]">
          <span class="text-navy-blue-800 text-caption-medium">Cancel</span>
        </button>
      </div>
    </form>
  </div>
</div>