import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../beans/User';
import { ApiResponse } from '../beans/ApiResponse';

@Injectable({
    providedIn: 'root',
})
export class EntitlementsService {
    private endpoint;

    constructor(private httpClient: HttpClient) {
        this.endpoint = environment.api_auth_endpoint;
    }

    getUser() {
        const url = this.endpoint + '/user/detail';
        return this.httpClient.get<ApiResponse<any>>(url);
    }

    updateUser(user: Record<string, any>) {
        const url = this.endpoint + '/user/detail';
        return this.httpClient.patch<ApiResponse<any>>(url, JSON.stringify(user));
    }

    changePassword(passwordsBody: any) {
        const url = this.endpoint + '/user/password/change';
        return this.httpClient.patch<ApiResponse<any>>(url, passwordsBody);
    }

    setPassword(passwordsBody: any) {
        const url = this.endpoint + '/user/password/set';
        return this.httpClient.patch<ApiResponse<any>>(url, passwordsBody);
    }

    verifyEmail(code: string) {
        const url = this.endpoint + '/user/email/otp';
        const data = {
            "code": code,
            "user_attribute": "email"
        }
        return this.httpClient.post<ApiResponse<any>>(url, data);
    }
};
