<div class="flex flex-col mt-[32px] items-center mb-[134px]">
    <div class="flex flex-col w-[900px]">
        <div class="flex flex-col items-center mb-[40px]">
            <ngx-skeleton-loader [theme]="{ height: '70px', width: '70px', margin: '0px' }"
                class="mb-[16px]"></ngx-skeleton-loader>

            <div class="flex flex-col w-[223px] h-[52px] bg-grey-bg-200 items-center">
                <ngx-skeleton-loader [theme]="{ height: '30px', width: '223px', margin: '0px' }"
                    class="mb-[4px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '21px', width: '201px', margin: '0px' }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="flex flex-row">
            <ngx-skeleton-loader [theme]="{ height: '27px', width: '138px', margin: '0px' }"
                class="mb-[8px]"></ngx-skeleton-loader>
        </div>
        <ngx-skeleton-loader [theme]="{ height: '21px', width: '500px', margin: '0px' }"
            class="mb-[16px]"></ngx-skeleton-loader>
        <div class="flex flex-col w-[900px] h-[240px] bg-grey-bg-200">
            <div class="flex flex-row mt-[16px] ml-[24px]">
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '73px', margin: '0px' }"
                    class="mr-[195px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '300px', margin: '0px' }"></ngx-skeleton-loader>
            </div>
            <div class="flex flex-row mt-[16px] ml-[24px]">
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '112px', margin: '0px' }"
                    class="mr-[156px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '300px', margin: '0px' }"></ngx-skeleton-loader>
            </div>
            <div class="flex flex-row mt-[16px] ml-[24px]">
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '116px', margin: '0px' }"
                    class="mr-[152px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '300px', margin: '0px' }"></ngx-skeleton-loader>
            </div>
            <div class="flex flex-row mt-[40px] ml-[24px]">
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '117px', margin: '0px' }"
                    class="mr-[195px]"></ngx-skeleton-loader>
            </div>
        </div>
        <ngx-skeleton-loader [theme]="{ height: '27px', width: '159px', margin: '0px' }"
            class="mt-[40px] mb-[8px]"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ height: '21px', width: '500px', margin: '0px' }"
            class="mb-[16px]"></ngx-skeleton-loader>
        <div class="flex flex-row">
            <div class="flex flex-col w-[442px] h-[208px] bg-grey-bg-200 pl-[24px]">
                <ngx-skeleton-loader [theme]="{ height: '32px', width: '32px', margin: '0px' }"
                    class="mt-[16px] mb-[16px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '109px', margin: '0px' }"
                    class="mb-[8px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '219px', margin: '0px' }"
                    class="mb-[24px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '105px', margin: '0px' }"></ngx-skeleton-loader>
            </div>
            <div class="flex flex-col w-[442px] h-[208px] ml-[16px] bg-grey-bg-200 pl-[24px]">
                <ngx-skeleton-loader [theme]="{ height: '32px', width: '32px', margin: '0px' }"
                    class="mt-[16px] mb-[16px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '75px', margin: '0px' }"
                    class="mb-[8px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '194px', margin: '0px' }"
                    class="mb-[24px]"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ height: '24px', width: '139px', margin: '0px' }"></ngx-skeleton-loader>
            </div>
        </div>
    </div>
</div>