import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from '../../environments/environment';
import { ApiResponse } from "../beans/ApiResponse";

@Injectable({
    providedIn: 'root'
})
export class ReferenceService {
    private orgQuickoCmsEndpoint: string;
    referenceData: BehaviorSubject<any> | undefined;

    constructor(private httpClient: HttpClient) {
        this.orgQuickoCmsEndpoint = environment.org_quicko_cms_endpoint;
    }

    getCountries() {
        const url = this.orgQuickoCmsEndpoint + '/api/org-quicko-country-flags?populate=*';
        return this.httpClient.get<ApiResponse<any>>(url);
    }

}