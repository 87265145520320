<div class="flex flex-col mt-[32px] items-center mb-[134px]">
  <div class="flex flex-col w-[603px]">
    <div class="flex flex-row">
      <button routerLink="../" class="flex flex-row content-center">
        <mat-icon class="material-symbols-rounded text-navy-blue-600 my-icon">arrow_back</mat-icon>
        <span class="text-navy-blue-600 text-caption-medium ml-[4px]">Back</span>
      </button>
    </div>
    <div class="flex flex-col mt-[8px]">
      <span class="text-body-2-medium text-dark">Change Email Address</span>
      <span class="mt-[4px] text-body-1-regular text-medium">Changes to your email address will be reflected across your
        Sandbox account</span>
    </div>
    <form [formGroup]="emailForm">
      <div class="flex flex-col mt-[40px]">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="shadow-navy-blue-600 w-[600px] text-border-grey rounded-md">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="email" type="email" placeholder="Email Address"
              class="text-body-1-medium text-dark">
            <mat-error>
              <ng-container *ngIf="
                          emailForm.controls['email'].errors &&
                          (emailForm.controls['email'].dirty || emailForm.controls['email'].touched)
                        ">
                <ng-container *ngFor="let error of emailForm.controls['email'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-row mt-[16px]">
        <ng-container *ngIf="!proceedToVerify; else proceed">
          <button (click)=" isValid() ? updateEmail():showError()" type="submit" mat-flat-button color="primary"
            class="w-[120px] disabled:bg-slate-400 h-[48px]">
            <span class="text-white text-caption-medium">Proceed</span>
          </button>
        </ng-container>
        <ng-template #proceed>
          <button mat-flat-button class="w-[120px] rounded-md bg-navy-blue-800 px-[16px] py-[10px] h-[48px]">
            <canvas riv="console-cta-button-loading-animation" width="48" height="48">
              <riv-player name="loading-1" play></riv-player>
            </canvas>
          </button>
        </ng-template>

        <button type="submit" mat-stroked-button routerLink="../" class="ml-4 h-[48px] min-w-[120px]">
          <span>Cancel</span>
        </button>
      </div>
    </form>
  </div>
</div>