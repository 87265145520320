import { email, notEmpty, pattern, prop } from '@rxweb/reactive-form-validators';
import { Expose } from 'class-transformer';
import 'reflect-metadata';
import { BaseObject } from './BaseObject';

@Reflect.metadata('@entity', 'in.co.sandbox.user')
export class User extends BaseObject {

    @Expose({ name: '@entity' })
    @prop()
    entity: string = 'in.co.sandbox.user';

    @Expose({ name: 'email' })
    @prop()
    @email({ message: 'Invalid email' })
    @notEmpty({ message: 'Email is required' })

    email?: string;

    @Expose({ name: 'first_name' })
    @prop()
    @notEmpty({ message: 'First name is required' })
    firstName?: string;

    @Expose({ name: 'last_name' })
    @prop()
    @notEmpty({ message: 'Last name is required' })
    lastName?: string;

    @Expose({ name: 'business' })
    @prop()
    @notEmpty({ message: 'Business name is required' })
    business?: string;

    @Expose({ name: 'phone' })
    @prop()
    @pattern({
        expression: {
            phone: new RegExp(/^\d{5} \d{5}$/)
        }, message: "Invalid phone number"
    })
    phone?: string;

    @Expose({ name: 'country_code' })
    @prop()
    countryCode?: string;

    @Expose({ name: 'password_updated_at' })
    @prop()
    passwordUpdatedAt?: number;

    @Expose({ name: 'is_password_set' })
    @prop()
    isPasswordSet?: boolean;

}
