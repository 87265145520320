import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { zip } from 'rxjs';
import { environment } from '../environments/environment';
import { HeaderComponent } from './components/common/header/header.component';
import { AuthService } from './services/auth.service';
import { ReferenceDataActions } from './store/reference-data/reference-data.actions';
import { UserActions } from './store/user/user.actions';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, StoreModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'my-account-app';
  loading = true;
  constructor(private store: Store, private actions$: Actions, private authService: AuthService) { }
  ngOnInit(): void {
    if (!environment.production) {
      this.authService.setToken(
        'eyJraWQiOiJKblwvdmhvSGhTZ29McjhGY1I1RG4xTGdTb3AzS3NHeVhhRkFYR3NiYjlxVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlMTZlODg3Yi0zZTcxLTQyNDEtOGQwOS0wNWE0MTgzZjkyYTMiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX2hxMjBsdEtFMiIsImNsaWVudF9pZCI6IjIxbTdibDZtZG0ydW9tdjA0MWM2cGVtNWs2Iiwib3JpZ2luX2p0aSI6ImQ4YmFlNjIzLTVkZTUtNGM5Yy1iZTliLWVlNzhhNGZiYjRhZiIsImV2ZW50X2lkIjoiOGZkZTk0YjMtYzdhMy00OTI1LWFjYzMtNGFiN2M1ODFmY2Q2IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTczMzIwNDkyOSwiZXhwIjoxNzMzMjkxMzI5LCJpYXQiOjE3MzMyMDQ5MjksImp0aSI6IjcwOTQzMGQxLWExNGUtNGE5OC1iYzMxLTE4ZDk5YzRmYzA4MyIsInVzZXJuYW1lIjoiNjAzNWNjYWUtMGI0Zi00MTQ0LTk5ZjItMTE3YzYwOTc3ODhjIn0.1aASt8f1SfwKZOhzwQZ41v-ilF6tzO7U6bFRIgHiiR4PO8DE-l6oafxBMapJV0wJnRwNZPVp-xg7Y7IKYiDcAS1ijBpXMyiNQkMlvE31C03q6v9twUz2RasyiOLJc5Hjh1VBnRMlNx5dnkdHSavAKL5G237aOMfctTQHmvkIL0o_K_9AnEoyDFBDE34G182m93CocsOVqw4APpMQuIcU2HyO5DWWsyBOSRtdMsr-VehwrhYyDF3p8R_3oJgLqds0Y-tLeqPIQLZja05ti-YNLnsylvs6VtkkwiXDj6dMYXXEljvhs4Kg2rGVIckdQt4Xgy__Yp5fBeyUkW4kqcjFFw'
      )
    }

    this.store.dispatch(ReferenceDataActions.GET_COUNTRIES());
    this.store.dispatch(UserActions.GET_USER())


    zip(this.actions$.pipe(ofType(ReferenceDataActions.GET_COUNTRIES_SUCCESS)),
      this.actions$.pipe(ofType(UserActions.GET_USER_SUCCESS))
    ).subscribe(([a, b]) => {
      this.loading = false;
    });


  }

}
