import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { EntitlementsService } from "../services/entitlements.service";
import { Observable, map } from "rxjs";
import { inject } from "@angular/core";

export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Observable<boolean> => {

    const entitlementsService = inject(EntitlementsService);
    const router = inject(Router)

    return entitlementsService.getUser().pipe(
        map(response => {

            if (response.code === 200 && response.data && !response.data.is_password_set) {
                return true;
            } else {
                router.navigate(['./']);
                return false;
            }
        })
    );
}



export const isPasswordSetGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    canActivate(route, state)
