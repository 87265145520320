<div class="flex flex-col w-[680px] px-[24px] py-[32px]">
    <div class="flex flex-row justify-between">
        <div class="flex flex-col w-[475px]">
            <span class="text-body-1-semibold text-dark">Change Email Address</span>
            <span class="text-caption-medium font-normal text-medium">Please enter the OTP sent to <span
                    class="text-caption-medium text-navy-blue-800">{{this.data.userEmail}}</span></span>
        </div>
        <div class="flex flex-col align">
            <button mat-dialog-close class="">
                <mat-icon class="material-symbols-rounded self-stretch w-[32px] h-[32px] text-light">cancel</mat-icon>
            </button>
        </div>
    </div>
    <div class="flex flex-col mt-[24px]">
        <ng-otp-input (onInputChange)="onOtpChange($event)" (keydown.enter)="verify()" [config]="{length:6, allowNumbersOnly: true, inputStyles: {
            'font-size': '20px',
            'font-weight': '500',
            'width': '46px',
            'height': '56px'  
            }}">
        </ng-otp-input>
    </div>
    <div>
        <button *ngIf="timeLeft != 0" mat-button [disabled]="timeLeft != 30" (click)="generateOTP()"
            [ngClass]="timeLeft == 0 ? 'text-blue-900' : 'text-medium'" class="font-medium text-base mt-4 grow-0">Resend
            OTP<span *ngIf="timeLeft != 30 && timeLeft != 0"> (Available in {{transform(timeLeft)}})</span>
        </button>
        <button *ngIf="timeLeft == 0" mat-button (click)="generateOTP()"
            class="font-normal text-base mt-4 grow-0 text-blue-800">
            Resend OTP
        </button>
    </div>

    <div class="flex flex-row mt-[32px]">

        <ng-container *ngIf="!showRive; else proceed">
            <button (click)="verify()" type="submit" mat-flat-button color="primary"
                class="w-[120px] disabled:bg-slate-400 h-[48px]">
                <span class="text-white text-caption-medium">Verify</span>
            </button>
        </ng-container>

        <ng-template #proceed>
            <button mat-flat-button color="primary" class="w-[120px] h-[48px]">
                <canvas riv="console-cta-button-loading-animation" width="48" height="48">
                    <riv-player name="loading-1" play></riv-player>
                </canvas>
            </button>
        </ng-template>

        <button mat-stroked-button mat-dialog-close type="submit" class="ml-4 h-[48px] min-w-[120px]">
            <span class="text-navy-blue-800 text-caption-medium">Back</span>
        </button>

    </div>

</div>