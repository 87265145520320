<div class="flex flex-col mt-[32px] items-center mb-[134px]">
  <div class="flex flex-col w-[603px]">
    <div class="flex flex-row">
      <button routerLink="../" class="flex flex-row content-center">
        <mat-icon class="material-symbols-rounded text-navy-blue-600 size-5">arrow_back</mat-icon>
        <span class="text-navy-blue-600 text-body-1-medium ml-[4px]">Back</span>
      </button>
    </div>
    <div class="flex flex-col mt-[8px] mb-[40px]">
      <span class="text-body-2-medium">Change Password</span>
      <span class="mt-[4px] text-body-1-regular text-medium">Set a strong password to secure your account</span>
    </div>
    <form [formGroup]="passwordForm">
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="shadow-navy-blue-600 w-[600px] text-border-grey rounded-md">
            <mat-label>Current Password</mat-label>

            <input matInput formControlName="old_password" type="password" [type]="showPassword ? 'text' : 'password'"
              onpaste="return false" ondrop="return false" autocomplete="off" placeholder="Current Password"
              class="text-caption-medium text-dark">
            <mat-error>
              <ng-container *ngIf="
                                passwordForm.controls['old_password'].errors &&
                                (passwordForm.controls['old_password'].dirty || passwordForm.controls['old_password'].touched)
                              ">
                <ng-container *ngFor="let error of passwordForm.controls['old_password'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>

            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showPassword=false"
              *ngIf="showPassword">
              visibility</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showPassword=true"
              *ngIf="!showPassword">
              visibility_off</mat-icon>

          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-col my-2">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="shadow-navy-blue-600 w-[600px] text-border-grey rounded-md">
            <mat-label>New Password</mat-label>
            <input matInput formControlName="new_password" type="password"
              [type]="showNewPassword ? 'text' : 'password'" autocomplete="off" placeholder="New Password"
              class="text-caption-medium text-dark">


            <mat-error>
              <ng-container *ngIf="
                                passwordForm.controls['new_password'].errors &&
                                (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)
                              ">
                <ng-container *ngFor="let error of passwordForm.controls['new_password'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>

            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showNewPassword=false"
              *ngIf="showNewPassword">
              visibility</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showNewPassword=true"
              *ngIf="!showNewPassword">
              visibility_off</mat-icon>
          </mat-form-field>
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex flex-row gap-2">
            <mat-icon *ngIf="!isLengthValid"
              class="material-symbols-rounded text-blue-grey-300 ">check_circle</mat-icon>
            <mat-icon *ngIf="isLengthValid" class="material-symbols-rounded text-navy-blue-800">check_circle</mat-icon>
            <p class="text-caption-medium">Must be at least 8 characters long</p>
          </div>

          <div class="flex flex-row gap-2">
            <mat-icon *ngIf="!isLowercaseValid"
              class="material-symbols-rounded text-blue-grey-300">check_circle</mat-icon>
            <mat-icon *ngIf="isLowercaseValid"
              class="material-symbols-rounded text-navy-blue-800">check_circle</mat-icon>
            <p class="text-caption-medium">Must contain at least one lowercase character</p>
          </div>

          <div class="flex flex-row gap-2">
            <mat-icon *ngIf="!isUppercaseValid"
              class="material-symbols-rounded text-blue-grey-300">check_circle</mat-icon>
            <mat-icon *ngIf="isUppercaseValid"
              class="material-symbols-rounded text-navy-blue-800">check_circle</mat-icon>
            <p class="text-caption-medium">Must contain at least one uppercase character</p>
          </div>

          <div class="flex flex-row gap-2">
            <mat-icon *ngIf="!isNumberValid" class="material-symbols-rounded text-blue-grey-300">check_circle</mat-icon>
            <mat-icon *ngIf="isNumberValid" class="material-symbols-rounded text-navy-blue-800">check_circle</mat-icon>
            <p class="text-caption-medium">Must contain at least one number</p>
          </div>

          <div class="flex flex-row gap-2">
            <mat-icon *ngIf="!isSpecialCharacterValid"
              class=" material-symbols-rounded text-blue-grey-300">check_circle</mat-icon>
            <mat-icon *ngIf="isSpecialCharacterValid"
              class=" material-symbols-rounded text-navy-blue-800">check_circle</mat-icon>
            <p class="text-caption-medium">Must include at least one special character</p>
          </div>
        </div>

      </div>

      <div class="flex flex-col mt-[20px]">
        <div class="flex flex-row items-center">
          <mat-form-field appearance="outline" class="w-[600px] shadow-navy-blue-600 text-border-grey rounded-md">
            <mat-label>Confirm Password</mat-label>
            <input matInput formControlName="confirm_password" type="password"
              [type]="showConfirmPassword ? 'text' : 'password'" onpaste="return false" ondrop="return false"
              autocomplete="off" placeholder="Confirm Password" class="text-caption-medium text-dark ">

            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showConfirmPassword=false"
              *ngIf="showConfirmPassword">
              visibility</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showConfirmPassword=true"
              *ngIf="!showConfirmPassword">
              visibility_off</mat-icon>

            <mat-error>
              <ng-container *ngIf="
                                passwordForm.controls['confirm_password'].errors &&
                                (passwordForm.controls['confirm_password'].dirty || passwordForm.controls['confirm_password'].touched)
                              ">
                <ng-container *ngFor="let error of passwordForm.controls['confirm_password'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>

          </mat-form-field>
        </div>
      </div>

    </form>
    <div class="flex flex-row mt-[16px]">
      <ng-container *ngIf="!showRive; else proceed">
        <button (click)="isValid()? resetPassword():showError()" type="submit" mat-flat-button color="primary"
          class="w-[120px]  h-[48px]">
          <span class="text-white text-caption-medium">Save</span>
        </button>
      </ng-container>
      <ng-template #proceed>
        <button mat-flat-button color="primary" class="w-[120px]  h-[48px]">
          <canvas riv="console-cta-button-loading-animation" width="48" height="48">
            <riv-player name="loading-1" play></riv-player>
          </canvas>
        </button>
      </ng-template>
      <button type="submit" mat-stroked-button routerLink="../" class="ml-4 h-[48px] min-w-[120px]">
        <span class="text-navy-blue-800 text-caption-medium">Cancel</span>
      </button>
    </div>
  </div>
</div>