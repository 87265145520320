import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ICON_REGISTRY_PROVIDER } from '@angular/material/icon';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { RIVE_FOLDER } from 'ng-rive';
import { CookieService } from 'ngx-cookie-service';
import { routes } from './app.routes';
import { CustomSerializer } from './serializers/custom-route.serializer';
import { RequestInterceptor } from './services/auth.service';
import { RootReducers } from './store/app.model';
import { ReferenceDataEffects } from './store/reference-data/reference-data.effects';
import { UserEffects } from './store/user/user.effects';

export const appConfig: ApplicationConfig = {
  providers: [

    provideRouter(routes),
    provideClientHydration(),
    provideStore(RootReducers),
    provideEffects(UserEffects, ReferenceDataEffects),
    provideRouterStore({
      serializer: CustomSerializer
    }),
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideStoreDevtools(),
    provideAnimations(),
    ICON_REGISTRY_PROVIDER,
    {
      provide: RIVE_FOLDER,
      useValue: "/assets/animations"
    },
  ]
};
